/* Standard best practice */
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

span {
  font-family: inherit;
  font-size: 1.3rem;
}

.body__main {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #615551;
  display: flex;
  margin: 20px;
  justify-content: center;
  background-color: #fffcff;
  min-height: calc(100vh - 2 * 4.5vw);
}

.ifr {
  display: none;
}

.container__main {
  width: 100%;
}

.container__hour {
  display: grid;
  grid-template-columns: 5rem minmax(auto, auto);
  grid-template-areas: "left right";
  width: 100%;
}

.hidden {
  display: none;
}

.fas_header {
  color: #247ba0;
  font-size: 2rem;
  padding: 0 10px 0 11px;
  cursor: pointer;
}

.ninedots {
  padding: 0 10px 0 11px;
}

.nav__main {
  width: 100%;
  /* padding: 0 3rem; */
  z-index: 100;
}

/* To fix the nav menu bar position when user scrolls down */
.nav__main.sticky {
  position: fixed;
  /* transition: all 1s; */
  background-color: rgba(255, 255, 255, 0.5);
  width: 98%;
}

.nav__bar {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.nav__btn {
  display: flex;
  align-items: center;
}

/* header > app dropdown box attributes. This is hidden at first*/

svg {
  height: 2rem;
  width: 2rem;
  fill: #3863be;
  transform: translateY(-1px);
}

.appbox {
  position: absolute;
  right: 0.5rem;
  z-index: 20;
  /* width: 11rem; */
  background-color: #fff;
  border-radius: 26px;
  border: none;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 8px;
  display: none;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s 0.2s;
}

.nav__btn--apps {
  height: 100%;
  font-family: inherit;
  color: inherit;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}

/* Remove default outline */
.nav__btn--apps:focus {
  outline: none;
}

/* Change the background color on hover over the button. This is needed as the dropdown menu items are not nav items */
.nav__btn--apps:hover {
  background-color: #f2efee;
  border-radius: 50%;
}

/* Show the app dropdown box on hover over the  */
.appbox:hover,
.nav__btn--apps:hover + .appbox {
  display: block;
}
.apps {
  display: inline-block;
  height: 84px;
  list-style-type: none;
  padding: 6px;
  position: relative;
  transition: transform 0.2s cubic-bezier(0.333, 0, 0, 1);
  vertical-align: top;
  width: 84px;
}

.appitem {
  border-radius: 8px;
  margin: 0;
  outline: none;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 78px;
  cursor: pointer;
}

.appitem:hover {
  background-color: #f9f5f3;
}

.appitembox {
  height: 45px;
  margin-top: 5px;
}

.applogo {
  display: inline-block;
  height: 40px;
  vertical-align: top;
  width: 40px;
  background-size: 40px 40px;
}

.applogo1 {
  background-image: url(./src/img/cms_logo.png);
}

.actionbtn {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  color: inherit;
  font-family: inherit;
  font-size: 1.3rem;
  user-select: none;
  width: 7rem;
  justify-self: center;
  margin-top: 1rem;
  margin-right: 1rem;
  text-align: center;
  display: inline-block;
}

/* To style the action buttons when it is enabled and on hover */
.actionbtn:not(.disabled):hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* To style the action buttons when it is disabled */
.actionbtn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

#monthDisplayButton {
  display: inline-block;
}

.header__month-display {
  color: #247ba0;
  font-size: 2rem;
  display: inline-block;
}

#selectedDateDisplay {
  width: 100%;
  color: #247ba0;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.weekdays {
  width: 100%;
  display: flex;
  color: #247ba0;
  font-size: 1.6rem;
}
#weekdays div {
  width: 14.2%;
  text-align: center;
  margin: 0;
  padding: 5px 0;
}
#calendar {
  width: 100%;
  height: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.day {
  width: 14.2%;
  height: 20%; /* need this to ensure equal height of each square even with events */
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 3px #cbd4c2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /*align the events from the top */
  margin: 0;
  padding: 10px 5px;
  font-size: 1.2rem;
  overflow-y: auto;
}
.day:hover {
  background-color: #f9f5f3;
}

.day + #currentDay {
  background-color: #e8f4fa;
}

.week {
  width: 14.2%;
  height: 20px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0;
  /* padding: 10px 5px; */
  font-size: 1.6rem;
}
.week:hover {
  color: #210af1;
  font-weight: bold;
}

.week__currentday {
  color: #ee150e;
  font-weight: bold;
}

.hour__list {
  grid-area: left;
}

.hour__event-list {
  grid-area: right;
  margin-top: 7px;
}

.hour__number {
  width: 100%;
  height: 40px;
  background-color: white;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  margin: 0;
  color: #94969b;
  font-size: 1rem;
  text-align: right;
  padding-right: 8px;
}

.hour__square {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 3px #cbd4c2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  color: #94969b;
  font-size: 0.88rem;
  /* padding: 10px 5px; */
}

.hour__square-allday {
  border: 2px solid rgb(179, 179, 189);
  padding: 5px;
  /* box-shadow: 6px 4px 8px; */
  background: #95aabe;
  background-clip: padding-box;
}

.event__hour {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 610;
  background-color: #d5e7ff;
  color: rgb(0, 1, 83);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  box-shadow: -3px 0px 0px 0px rgb(32, 99, 201);
}

.event__hour-edit {
  cursor: pointer;
}

.event__null {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.event__hour-15 {
  height: 33%;
}
.event__hour-30 {
  height: 50%;
}
.event__hour-45 {
  height: 75%;
}

.event__hour-60 {
  height: 85%;
}

.event__hour-start-15 {
  height: 75%;
  align-self: flex-end;
}
.event__hour-start-30 {
  height: 50%;
  align-self: flex-end;
}
.event__hour-start-45 {
  height: 40%;
  align-self: flex-end;
}

.event__hour-start-60 {
  height: 33%;
  align-self: flex-end;
}

.event__hour-start-15.event__hour-15 {
  height: 33%;
  align-self: flex-start;
  margin-top: 5px;
}

.event__hour-start-15.event__hour-30 {
  height: 40%;
  align-self: flex-start;
  margin-top: 10px;
}

.event__hour-start-15.event__hour-45 {
  height: 50%;
  align-self: center;
}

.event__hour-start-15.event__hour-60 {
  height: 70%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-30.event__hour-30 {
  height: 33%;
  align-self: center;
}

.event__hour-start-30.event__hour-45 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 10px;
}

.event__hour-start-30.event__hour-60 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-45.event__hour-45 {
  height: 33%;
  align-self: flex-end;
  margin-bottom: 10px;
}

.event__hour-start-45.event__hour-60 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-60.event__hour-60 {
  height: 33%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event {
  font-size: 1.1rem;
  font-weight: 610;
  padding: 1px;
  background-color: #d5e7ff;
  color: rgb(0, 1, 83);
  border-radius: 5px;
  min-height: 17px;
  max-height: 20px;
  overflow: hidden;
  margin-top: 0.1rem;
}

.event__multiday {
  background-color: #bcd4fc;
  border-radius: 1px;
}
.padding {
  cursor: default !important;
  background-color: #fffcff !important;
  box-shadow: none !important;
}
#newEventModal,
#deleteEventModal {
  display: none;
  z-index: 20;
  padding: 25px;
  background-color: #e8f4fa;
  box-shadow: 0px 0px 3px black;
  border-radius: 5px;
  width: 350px;
  top: 100px;
  left: calc(50% - 175px);
  position: absolute;
  font-family: sans-serif;
}
#eventTitleInput {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 25px;
  border-radius: 3px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 3px gray;
}
#eventTitleInput.error {
  border: 2px solid red;
}

#eventText {
  font-size: 14px;
}

.add-event-window,
.edit-event-window {
  max-height: 95%; /*need to set height for overflow to work, but we use max height so that the height still automatically fit the content instead of a fixed height */
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: white;
  border-radius: 5px;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 20;
  transition: all 0.5s;
}

.overlay {
  left: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}

.btn--close-modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 3.3rem;
  cursor: pointer;
  border: none;
  background: none;
}

.event__header {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.event__label {
  font-size: 1.3rem;
  font-weight: 600;
  color: inherit;
  display: block;
}

.form-date,
.form-time {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.event__input {
  color: inherit;
  background-color: #ffffff;
  font-size: 1.3rem;
  font-family: inherit;
  padding: 0.6rem 0.8rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  transition: all 0.2s;
  vertical-align: middle;
  margin-right: 1%;
  width: 100%;
}

.event__input:focus {
  outline: none;
  border: 1px solid #5068f0;
  background-color: #f9f5f3;
}

.event__input::placeholder {
  color: #d3c7c3;
}

.event__input-readonly {
  background-color: #e6e6e6;
  opacity: 40%;
}

.upload-row-padding {
  margin-bottom: 1rem;
}

.upload-row-inline {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media only screen and (max-width: 119em) {
  .add-event-window,
  .edit-event-window {
    width: 50%;
  }
  .container__main {
    height: 75vh;
  }
}

@media only screen and (max-width: 61.25em) {
  .add-event-window,
  .edit-event-window {
    width: 80%;
  }
}

@media only screen and (max-width: 31.25em) {
  .add-event-window,
  .edit-event-window {
    width: 95%;
    padding: 2rem 2rem;
  }
  html {
    font-size: 40%;
  }
  input {
    width: 100%;
  }
}

/* Login page css */

.body__login {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 2 * 4vw);
}

.container__login {
  max-width: 190rem;
  margin: 1vw auto;
  background-color: #15172b;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0 2rem 6rem 0.5rem rgba(#615551, 0.2);
}

.login {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: 500px;
  padding: 20px;
  width: 320px;
}

.login__header {
  color: #eee;
  font-family: sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  margin-top: 3rem;
  text-align: center;
}

.login__title {
  color: #eee;
  font-family: sans-serif;
  font-size: 3.6rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.login__subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.login__message {
  color: #dc2f55;
  font-family: sans-serif;
  font-size: 1.2rem;
  margin-top: 3rem;
  text-align: center;
}

.login__input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.login__ic1 {
  margin-top: 4rem;
}

.login__ic2 {
  margin-top: 3rem;
}

.login__input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 1.6rem;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.login__cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.login__input:focus ~ .login__cut,
.login__input:not(:placeholder-shown) ~ .login__cut {
  transform: translateY(8px);
}

.login__placeholder {
  color: #65657b;
  font-family: sans-serif;
  font-size: 1.6rem;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.login__input:focus ~ .login__placeholder,
.login__input:not(:placeholder-shown) ~ .login__placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.login__input:not(:placeholder-shown) ~ .login__placeholder {
  color: #808097;
}

.login__input:focus ~ .login__placeholder {
  color: #dc2f55;
}

.fa-eye-slash,
.fa-eye {
  color: #65657b;
  position: absolute;
  top: 20px;
  left: 250px;
  cursor: pointer;
}

.login__submit {
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.login__submit:hover {
  color: #b1b0f3;
  background-color: #07c;
}

.login__submit:active {
  background-color: #06b;
}

.login__spinner > svg {
  height: 3rem;
  width: 3rem;
  fill: #08d;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* For the All-day slider in add Event*/
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-switch {
  justify-content: space-between;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

span {
  font-family: inherit;
  font-size: 1.3rem;
}

.body__main {
  color: #615551;
  min-height: calc(100vh - 9vw);
  background-color: #fffcff;
  justify-content: center;
  margin: 20px;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
}

.ifr {
  display: none;
}

.container__main {
  width: 100%;
}

.container__hour {
  width: 100%;
  grid-template-columns: 5rem minmax(auto, auto);
  grid-template-areas: "left right";
  display: grid;
}

.hidden {
  display: none;
}

.fas_header {
  color: #247ba0;
  cursor: pointer;
  padding: 0 10px 0 11px;
  font-size: 2rem;
}

.ninedots {
  padding: 0 10px 0 11px;
}

.nav__main {
  width: 100%;
  z-index: 100;
}

.nav__main.sticky {
  width: 98%;
  background-color: #ffffff80;
  position: fixed;
}

.nav__bar {
  justify-content: space-between;
  padding: 5px;
  display: flex;
}

.nav__btn {
  align-items: center;
  display: flex;
}

svg {
  height: 2rem;
  width: 2rem;
  fill: #3863be;
  transform: translateY(-1px);
}

.appbox {
  z-index: 20;
  box-sizing: border-box;
  max-height: 30rem;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  padding: 8px;
  transition: all .5s .2s;
  display: none;
  position: absolute;
  right: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000004d;
}

.nav__btn--apps {
  height: 100%;
  color: inherit;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding: .5rem;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 700;
  transition: all .3s;
  display: flex;
}

.nav__btn--apps:focus {
  outline: none;
}

.nav__btn--apps:hover {
  background-color: #f2efee;
  border-radius: 50%;
}

.appbox:hover, .nav__btn--apps:hover + .appbox {
  display: block;
}

.apps {
  height: 84px;
  vertical-align: top;
  width: 84px;
  padding: 6px;
  list-style-type: none;
  transition: transform .2s cubic-bezier(.333, 0, 0, 1);
  display: inline-block;
  position: relative;
}

.appitem {
  text-align: center;
  width: 78px;
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  margin: 0;
  text-decoration: none;
  position: absolute;
}

.appitem:hover {
  background-color: #f9f5f3;
}

.appitembox {
  height: 45px;
  margin-top: 5px;
}

.applogo {
  height: 40px;
  vertical-align: top;
  width: 40px;
  background-size: 40px 40px;
  display: inline-block;
}

.applogo1 {
  background-image: url("cms_logo.9357b01b.png");
}

.actionbtn {
  box-sizing: border-box;
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  width: 7rem;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  justify-self: center;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  display: inline-block;
  box-shadow: 0 2px 5px #d5d9d980;
}

.actionbtn:not(.disabled):hover {
  cursor: pointer;
  color: #fff;
  background: #0003;
}

.actionbtn:disabled {
  opacity: .5;
  pointer-events: none;
}

#monthDisplayButton {
  display: inline-block;
}

.header__month-display {
  color: #247ba0;
  font-size: 2rem;
  display: inline-block;
}

#selectedDateDisplay {
  width: 100%;
  color: #247ba0;
  justify-content: center;
  padding: 20px 0;
  font-size: 1.6rem;
  display: flex;
}

.weekdays {
  width: 100%;
  color: #247ba0;
  font-size: 1.6rem;
  display: flex;
}

#weekdays div {
  width: 14.2%;
  text-align: center;
  margin: 0;
  padding: 5px 0;
}

#calendar {
  width: 100%;
  height: 90%;
  flex-wrap: wrap;
  margin: auto;
  display: flex;
}

.day {
  width: 14.2%;
  height: 20%;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 10px 5px;
  font-size: 1.2rem;
  display: flex;
  overflow-y: auto;
  box-shadow: 0 0 3px #cbd4c2;
}

.day:hover {
  background-color: #f9f5f3;
}

.day + #currentDay {
  background-color: #e8f4fa;
}

.week {
  width: 14.2%;
  height: 20px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  font-size: 1.6rem;
  display: flex;
}

.week:hover {
  color: #210af1;
  font-weight: bold;
}

.week__currentday {
  color: #ee150e;
  font-weight: bold;
}

.hour__list {
  grid-area: left;
}

.hour__event-list {
  grid-area: right;
  margin-top: 7px;
}

.hour__number {
  width: 100%;
  height: 40px;
  color: #94969b;
  text-align: right;
  background-color: #fff;
  margin: 0;
  padding-right: 8px;
  font-size: 1rem;
}

.hour__square {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: #94969b;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  font-size: .88rem;
  display: flex;
  box-shadow: 0 0 3px #cbd4c2;
}

.hour__square-allday {
  background: #95aabe padding-box padding-box;
  border: 2px solid #b3b3bd;
  padding: 5px;
}

.event__hour {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000153;
  background-color: #d5e7ff;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 610;
  display: flex;
  box-shadow: -3px 0 #2063c9;
}

.event__hour-edit {
  cursor: pointer;
}

.event__null {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.event__hour-15 {
  height: 33%;
}

.event__hour-30 {
  height: 50%;
}

.event__hour-45 {
  height: 75%;
}

.event__hour-60 {
  height: 85%;
}

.event__hour-start-15 {
  height: 75%;
  align-self: flex-end;
}

.event__hour-start-30 {
  height: 50%;
  align-self: flex-end;
}

.event__hour-start-45 {
  height: 40%;
  align-self: flex-end;
}

.event__hour-start-60 {
  height: 33%;
  align-self: flex-end;
}

.event__hour-start-15.event__hour-15 {
  height: 33%;
  align-self: flex-start;
  margin-top: 5px;
}

.event__hour-start-15.event__hour-30 {
  height: 40%;
  align-self: flex-start;
  margin-top: 10px;
}

.event__hour-start-15.event__hour-45 {
  height: 50%;
  align-self: center;
}

.event__hour-start-15.event__hour-60 {
  height: 70%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-30.event__hour-30 {
  height: 33%;
  align-self: center;
}

.event__hour-start-30.event__hour-45 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 10px;
}

.event__hour-start-30.event__hour-60 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-45.event__hour-45 {
  height: 33%;
  align-self: flex-end;
  margin-bottom: 10px;
}

.event__hour-start-45.event__hour-60 {
  height: 40%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event__hour-start-60.event__hour-60 {
  height: 33%;
  align-self: flex-end;
  margin-bottom: 5px;
}

.event {
  color: #000153;
  min-height: 17px;
  max-height: 20px;
  background-color: #d5e7ff;
  border-radius: 5px;
  margin-top: .1rem;
  padding: 1px;
  font-size: 1.1rem;
  font-weight: 610;
  overflow: hidden;
}

.event__multiday {
  background-color: #bcd4fc;
  border-radius: 1px;
}

.padding {
  cursor: default !important;
  box-shadow: none !important;
  background-color: #fffcff !important;
}

#newEventModal, #deleteEventModal {
  z-index: 20;
  width: 350px;
  background-color: #e8f4fa;
  border-radius: 5px;
  padding: 25px;
  font-family: sans-serif;
  display: none;
  position: absolute;
  top: 100px;
  left: calc(50% - 175px);
  box-shadow: 0 0 3px #000;
}

#eventTitleInput {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  outline: none;
  margin-bottom: 25px;
  padding: 10px;
  box-shadow: 0 0 3px gray;
}

#eventTitleInput.error {
  border: 2px solid red;
}

#eventText {
  font-size: 14px;
}

.add-event-window, .edit-event-window {
  max-height: 95%;
  width: 30%;
  z-index: 20;
  background-color: #fff;
  border-radius: 5px;
  padding: 5rem 6rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #00000040;
}

.overlay {
  z-index: 10;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: #0006;
  position: fixed;
  left: 0;
}

.btn--close-modal {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 3.3rem;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.event__header {
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.event__label {
  color: inherit;
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
}

.form-date, .form-time {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.event__input {
  color: inherit;
  vertical-align: middle;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .5rem;
  margin-right: 1%;
  padding: .6rem .8rem;
  font-family: inherit;
  font-size: 1.3rem;
  transition: all .2s;
}

.event__input:focus {
  background-color: #f9f5f3;
  border: 1px solid #5068f0;
  outline: none;
}

.event__input::placeholder {
  color: #d3c7c3;
}

.event__input-readonly {
  opacity: .4;
  background-color: #e6e6e6;
}

.upload-row-padding {
  margin-bottom: 1rem;
}

.upload-row-inline {
  width: 100%;
  flex-direction: row;
  display: flex;
}

@media only screen and (max-width: 119em) {
  .add-event-window, .edit-event-window {
    width: 50%;
  }

  .container__main {
    height: 75vh;
  }
}

@media only screen and (max-width: 61.25em) {
  .add-event-window, .edit-event-window {
    width: 80%;
  }
}

@media only screen and (max-width: 31.25em) {
  .add-event-window, .edit-event-window {
    width: 95%;
    padding: 2rem;
  }

  html {
    font-size: 40%;
  }

  input {
    width: 100%;
  }
}

.body__login {
  min-height: calc(100vh - 8vw);
  background-color: #000;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  display: flex;
}

.container__login {
  max-width: 190rem;
  box-shadow: 0 2rem 6rem .5rem rgba(#615551, .2);
  background-color: #15172b;
  border-radius: 9px;
  margin: 1vw auto;
  overflow: hidden;
}

.login {
  box-sizing: border-box;
  height: 500px;
  width: 320px;
  background-color: #15172b;
  border-radius: 20px;
  padding: 20px;
}

.login__header {
  color: #eee;
  text-align: center;
  margin-top: 3rem;
  font-family: sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
}

.login__title {
  color: #eee;
  text-align: center;
  margin-top: 1rem;
  font-family: sans-serif;
  font-size: 3.6rem;
  font-weight: 600;
}

.login__subtitle {
  color: #eee;
  text-align: center;
  margin-top: 1rem;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

.login__message {
  color: #dc2f55;
  text-align: center;
  margin-top: 3rem;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.login__input-container {
  height: 50px;
  width: 100%;
  position: relative;
}

.login__ic1 {
  margin-top: 4rem;
}

.login__ic2 {
  margin-top: 3rem;
}

.login__input {
  box-sizing: border-box;
  color: #eee;
  height: 100%;
  width: 100%;
  background-color: #303245;
  border: 0;
  border-radius: 12px;
  outline: 0;
  padding: 4px 20px 0;
  font-size: 1.6rem;
}

.login__cut {
  height: 20px;
  width: 76px;
  background-color: #15172b;
  border-radius: 10px;
  transition: transform .2s;
  position: absolute;
  top: -20px;
  left: 20px;
  transform: translateY(0);
}

.login__input:focus ~ .login__cut, .login__input:not(:placeholder-shown) ~ .login__cut {
  transform: translateY(8px);
}

.login__placeholder {
  color: #65657b;
  pointer-events: none;
  transform-origin: 0;
  font-family: sans-serif;
  font-size: 1.6rem;
  line-height: 14px;
  transition: transform .2s, color .2s;
  position: absolute;
  top: 20px;
  left: 20px;
}

.login__input:focus ~ .login__placeholder, .login__input:not(:placeholder-shown) ~ .login__placeholder {
  transform: translateY(-30px)translateX(10px)scale(.75);
}

.login__input:not(:placeholder-shown) ~ .login__placeholder {
  color: #808097;
}

.login__input:focus ~ .login__placeholder {
  color: #dc2f55;
}

.fa-eye-slash, .fa-eye {
  color: #65657b;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 250px;
}

.login__submit {
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  height: 50px;
  text-align: center;
  width: 100%;
  background-color: #08d;
  border: 0;
  border-radius: 12px;
  margin-top: 38px;
  font-size: 18px;
}

.login__submit:hover {
  color: #b1b0f3;
  background-color: #07c;
}

.login__submit:active {
  background-color: #06b;
}

.login__spinner > svg {
  height: 3rem;
  width: 3rem;
  fill: #08d;
  animation: 2s linear infinite rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.switch {
  width: 40px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  height: 13px;
  width: 13px;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: 3px;
  left: 3px;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-switch {
  justify-content: space-between;
}

/*# sourceMappingURL=index.ea32f0cc.css.map */
